import styled, { createGlobalStyle } from 'styled-components/macro';

interface ContainerProps {
  leftMargin: boolean;
}

export const Container = styled.div<ContainerProps>`
  height: 100vh;
  margin-left: ${({ leftMargin }) => (leftMargin ? '88px' : '')};
  overflow: hidden;
`;

interface StacksProps {
  length: number;
  viewOnly: boolean;
}

export const Stacks = styled.div<StacksProps>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: safe center;
  padding: 20px 4px ${({ viewOnly }) => (viewOnly ? '0' : '20px')} 32px;
  height: calc(100% - 88px); // 100% - header;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    background: transparent;
    width: 3px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.gray1};
    border-radius: 8px;
    width: 3px;
  }

  /* Fix for a Safari bug when content is overflowing the width the scroll was at the center */
  /* 292 = 272px + 20px (gap) */
  /* 88 from sidebar */
  @media screen and (max-width: calc((${({ length }) =>
      length} * 292px) + 88px)) {
    justify-content: flex-start;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 20px 24px;
  align-items: center;
  height: 88px;
  border-bottom: 1px solid ${({ theme }) => theme.gray};
`;

export const ProjectStatus = styled.p`
  color: ${({ theme }) => theme.black};
  opacity: 0.3;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 120%;
`;

export const Actions = styled.div`
  display: flex;
  gap: 12px;
`;

export const GlobalStyle = createGlobalStyle`
  #root {
    overflow: unset;
  }
`;

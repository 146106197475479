import styled, { createGlobalStyle } from 'styled-components/macro';
import { Button } from '../../../../../../../../components/Button/Button';

export const Container = styled.div`
  width: 100%;
  min-height: 80px;
  display: flex;
  background: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.gray2};
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 9px;
  margin-right: 0;
  border-radius: 12px;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
`;

interface HeadersProps {
  connect: boolean;
}

export const Header = styled.header<HeadersProps>`
  width: 100%;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    width: 1px;
    background: ${({ theme }) => theme.gray2};
    bottom: 0;
    top: 5px;
    left: 12px;
    position: absolute;
    margin-bottom: -10px;
    display: ${({ connect }) => (connect ? 'initial' : 'none')};
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
  justify-content: space-between;
  height: 100%;
  padding: 0 12px 0 32px;
  margin-top: 8px;
`;

export const AvatarWrapper = styled.div``;

export const Avatar = styled.img`
  max-height: 24px;
  min-width: 24px;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  object-fit: cover;
  z-index: 1;
`;

export const Username = styled.p`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  text-align: left;
  margin-left: 8px;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  color: ${({ theme }) => theme.gray3};
`;

export const Date = styled.p`
  height: 14px;
  min-width: 56px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: ${({ theme }) => theme.gray1};
  text-align: right;
`;

interface ShowDrawingButtonProps {
  active: boolean;
}

export const ShowDrawingButton = styled(Button)<ShowDrawingButtonProps>`
  min-width: 125px;
  max-width: 141px;
  height: 24px;
  margin: 0;
  border-radius: 4px;
  background: ${({ active, theme }) => (active ? theme.blue : theme.gray2)};
  color: ${({ active, theme }) => (active ? theme.white : theme.gray3)};
  font-weight: 400;
  font-size: 12px;
  display: grid;
  grid-template-columns: 15px 100px 14px;
  align-items: center;
  align-self: flex-start;
  padding: 4px 8px;

  &:hover {
    color: ${({ theme }) => theme.white};

    svg:first-child {
      path {
        fill: ${({ theme }) => theme.white};
      }
    }
  }

  svg {
    &:first-child {
      width: 12px;
      height: 12px;

      path {
        fill: ${({ active, theme }) => (active ? theme.white : '#7f8087')};
      }
    }

    &:nth-child(2) {
      font-size: 16px;
      cursor: pointer;
    }
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    text-align: left;
  }
`;

export const Counter = styled.span`
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: ${({ theme }) => theme.blue};
  margin-left: 8px;
`;

export const GlobalStyle = createGlobalStyle`
  #remove-comment {
    margin: 5px 0 0 3px !important;

    .arrow {
      margin-top: 1px !important;
      margin-left: -5px !important;
    }
  }
`;

import React, { useEffect, useMemo, useState } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { HiPlus } from 'react-icons/hi';
import { Link } from 'react-router-dom';

import ROUTES from '../../routes';
import {
  BoardSimplified,
  COMMUNITY_TEAM_ID,
  DashboardSettings,
  PubSubEvent,
  TeamSimplifiedViewModel,
} from '../../typings';
import DateUtils from '../../utils/date.utils';

import {
  Container,
  Content,
  Header,
  Title,
  Item,
  Image,
  Name,
  UpdatedAt,
  Toggle,
  CreateButton,
  EmptyText,
  LogoWrapper,
  LoaderWrapper,
} from './ProjectsSideBar.styles';
import { useApp } from '../../hooks/useMain';
import Logo from '../Icon/icons/Logo';
import Tooltip from '../Tooltip/Tooltip';
import SetFavoriteModal from './components/SetFavoriteModal/SetFavoriteModal';
import AuthService from '../../services/auth.service';
import { useSub } from '../../hooks/usePubSub';
import FavoriteClient from '../../clients/favorite.client';

type Favorite = TeamSimplifiedViewModel | BoardSimplified;

interface ProjectsSideBarProps {
  disabled?: boolean;
  activeItem?: number;
  headerHeight?: string;
}

const ProjectsSideBar = ({
  activeItem,
  disabled = false,
  headerHeight = '88px',
}: ProjectsSideBarProps) => {
  const { setErrorMessage } = useApp();
  const [data, setData] = useState<Array<Favorite>>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  function isTeam(item: Favorite): boolean {
    return 'members' in item && 'boards' in item;
  }

  function getKey(item: Favorite): string {
    const isTeamSelected = isTeam(item);
    const result = `${isTeamSelected ? 'team' : 'board'}-${item.id}`;

    return result;
  }

  async function fetchData() {
    try {
      setLoading(true);

      const result = await new FavoriteClient().getAll();

      setData(result);
    } catch (error: any) {
      setErrorMessage(
        error?.data?.message || 'Something went wrong while fetching the data'
      );
    } finally {
      setLoading(false);
    }
  }

  function isCommunityGroup(item: Favorite): boolean {
    return item.id === COMMUNITY_TEAM_ID;
  }

  function handleModalClose() {
    setShowModal(false);
    fetchData();
  }

  const isAuthenticated = useMemo((): boolean => {
    const result = new AuthService().isAuthenticated();

    return result;
  }, [data]);

  useEffect(() => {
    if (!data.length && isAuthenticated) {
      fetchData();
    }
  }, []);

  useSub(PubSubEvent.PROJECT_DELETED, (id: number) => {
    fetchData();
  });

  useSub(PubSubEvent.TEAM_DELETED, (id: number) => {
    fetchData();
  });

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Container open={open}>
      <Header height={headerHeight}>
        <Link reloadDocument to={ROUTES.DASHBOARD}>
          <Tooltip id="logo" title="Home" placement="right">
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          </Tooltip>
        </Link>
      </Header>

      <Content disabled={disabled}>
        {loading && (
          <LoaderWrapper>
            <BeatLoader color="#7F8087" size={10} loading />
          </LoaderWrapper>
        )}

        {!loading &&
          data.map((item) => (
            <Link
              reloadDocument
              key={getKey(item)}
              to={
                isTeam(item)
                  ? ROUTES.GROUP_VIEW(String(item.id))
                  : ROUTES.PROJECT_VIEW(String(item.id))
              }
            >
              <Item>
                <Tooltip
                  title={item.name}
                  id={item.name}
                  placement="right"
                  show={open ? false : undefined}
                >
                  <Image
                    src={item.image}
                    title={item.name}
                    open={open}
                    selected={item.id === activeItem}
                  />
                </Tooltip>

                <span>
                  <Name>{item.name}</Name>
                  <UpdatedAt>
                    Updated {new DateUtils(item.updatedAt).fromNow()}
                  </UpdatedAt>
                </span>
              </Item>
            </Link>
          ))}
      </Content>

      {isAuthenticated && (
        <Tooltip id="create-button" title="Pin to sidebar" placement="right">
          <CreateButton type="button" onClick={() => setShowModal(true)}>
            <HiPlus color="#4D4DFF" />
          </CreateButton>
        </Tooltip>
      )}

      <SetFavoriteModal
        open={showModal}
        onClose={handleModalClose}
        favorites={data}
      />
    </Container>
  );
};

export default ProjectsSideBar;

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import AuthService from '../services/auth.service';
import TokenClient from './token.client';

const NOT_AUTHENTICATED = 401;
const SERVICE_UNAVAILABLE = 503;

class BaseClient {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: '/api',
      timeout: 0,
    });

    this.client.interceptors.request.use((request: AxiosRequestConfig) => {
      const token = new AuthService().getIdToken();

      if (!request.headers.Authorization && token) {
        request.headers.Authorization = token;
      }

      return request;
    });

    this.client.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: any) => {
        const originalRequest = error.config;
        const isNotAuthenticated = error.response.status === NOT_AUTHENTICATED;
        const isTokenExpired = error.response.status === SERVICE_UNAVAILABLE;
        const refreshToken = new AuthService().getRefreshToken();

        if (refreshToken && (isNotAuthenticated || isTokenExpired)) {
          const { accessToken, idToken } = await new TokenClient().refreshToken(
            refreshToken
          );
          new AuthService().saveToken(accessToken);
          new AuthService().saveIdToken(idToken);

          originalRequest.headers.Authorization = idToken;
          originalRequest._retry = true;
          return axios.request(originalRequest);
        }

        if (!refreshToken && isNotAuthenticated) {
          new AuthService().logout();
          window.location.assign(window.location.origin);
        }

        return Promise.reject(error.response);
      }
    );
  }
}

export default BaseClient;

import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  scroll-margin: 100px;
  margin-right: 20px;
`;

export const Header = styled.header``;

export const Content = styled.div`
  display: flex;
  height: calc(100% - 68px); // 68px = 48px (Header) + 20px (gap)
`;

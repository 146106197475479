import React, { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { CgTrash as IconDelete } from 'react-icons/cg';
import { DotsSixVertical } from '@phosphor-icons/react';

import { AssetsCount, Container, Input, Actions } from './Name.styles';
import { ButtonTransparent } from '../../../../../../components/Button/Button';
// import ColumnOptions from '../Options/Options';

enum ColumnAction {
  DELETE = 'Delete stack',
}

interface NameProps {
  name: string;
  placeholder?: string;
  totalAssets: number;
  stack: {
    first: boolean;
    last: boolean;
    single: boolean;
  };
  disabled: boolean;
  dragProps?: any;
  onChange: (value: string) => void;
  onDelete?: () => void;
  onClick?: () => void;
}

const Name: React.FC<NameProps> = ({
  name,
  dragProps,
  placeholder,
  totalAssets,
  stack,
  disabled,
  onChange,
  onDelete,
  onClick,
}: NameProps) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebouncedCallback((value: string) => {
    onChange(value);
  }, 500);

  const options = useMemo(() => {
    const result = [
      {
        name: ColumnAction.DELETE,
        icon: <IconDelete />,
        disabled: stack.single,
      },
    ];

    return result;
  }, [stack]);

  // function handleOptionChange(value: string) {
  //   if (value === ColumnAction.DELETE && onDelete) onDelete();
  // }

  function handleChange(value: string) {
    debouncedInputValue(value);
    setInputValue(value);
  }

  function handleClick() {
    if (disabled) {
      onClick?.();
    }
  }

  useEffect(() => {
    setInputValue(name);
  }, [name]);

  return (
    <Container disabled={disabled} onClick={handleClick}>
      <Input
        type="text"
        placeholder={placeholder}
        value={inputValue}
        disabled={disabled}
        onChange={(e) => handleChange(e.target.value)}
      />

      {!disabled ? (
        // <ColumnOptions items={options} onChange={handleOptionChange} />
        <Actions>
          <ButtonTransparent
            type="button"
            marginTop="0"
            width="30px"
            height="32px"
            {...dragProps}
          >
            <DotsSixVertical
              size={20}
              color="#7F8087"
              weight="bold"
              cursor="grab"
            />
          </ButtonTransparent>
          <IconDelete
            size={20}
            color="#FF5656"
            style={{ cursor: 'pointer' }}
            onClick={onDelete}
          />
        </Actions>
      ) : (
        <AssetsCount>{totalAssets}</AssetsCount>
      )}
    </Container>
  );
};

export default Name;

import React, { useMemo } from 'react';
import { BoardItem } from '../../../../../../typings';
import { Container, Picture } from './Image.styles';
import ImageUtils from '../../../../../../utils/image.utils';

interface ImageProps {
  item: BoardItem;
  disabled: boolean;
  onClick: (item: BoardItem) => void;
}

const Image = ({ item, disabled, onClick }: ImageProps) => {
  const src = useMemo(() => {
    if (item.value instanceof File) {
      return URL.createObjectURL(item.value);
    }

    const smallImg = new ImageUtils(item.value).getSmall();

    return smallImg.split('?')[0];
  }, [item.value]);

  return (
    <Container disabled={disabled}>
      <Picture src={src} onClick={() => onClick(item)} />
    </Container>
  );
};

export default Image;

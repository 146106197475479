import styled, { createGlobalStyle } from 'styled-components/macro';
import { Picture } from './components/Image/Image.styles';
import { Container as Note } from './components/Note/Note.styles';

interface ContainerProps {
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  padding: 0;
  margin: 0;
  height: 100%;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`;

interface ContentProps {
  disabled: boolean;
  focused: boolean;
  ref: any;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ disabled }) => (disabled ? '8px' : '12px')};
  outline: 2px solid
    ${({ focused, theme }) => (focused ? theme.blue : 'transparent')};
  background-color: ${({ theme, disabled }) =>
    disabled ? 'transparent' : theme.gray2};
  height: 100%;
  width: 272px;
  padding: ${({ disabled }) => (disabled ? '0' : '12px 12px 0 12px')};
`;

export const Description = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${({ theme, color }) => color || theme.gray1};
`;

export const EmptyContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
`;

export const DataWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const UploadButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  gap: 6px;

  width: 105px;
  height: 40px;

  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.gray};
  border-radius: 12px;

  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.gray3};
`;

export const GlobalStyle = createGlobalStyle`
  .react-sortable-hoc-on-drag {
    ${Picture} {
      border: 2px solid #4D4DFF;
    }

    ${Note} {
      border: 1px solid #4D4DFF;
    }
  }
`;

import React, { RefObject } from 'react';
import mergeRefs from 'merge-refs';

import { Container } from './Items.styles';
import Item from '../Item/Item';
import { BoardItem } from '../../../../../../typings';

interface ItemsProps {
  draggableId: string;
  items: BoardItem[];
  disabled: boolean;
  onRemove: (item: BoardItem) => void;
  onChange: (item: BoardItem) => void;
  onImageClick: (item: BoardItem) => void;
  reference: RefObject<HTMLDivElement>;
  dropProvided: any;
}

const Items = ({
  draggableId,
  items,
  disabled,
  reference,
  onRemove,
  onChange,
  onImageClick,
  dropProvided,
}: ItemsProps) => {
  function getKey(item: BoardItem): string {
    if (item.value instanceof File) {
      return item.value.name;
    }

    if (item.createdAt instanceof Date) {
      return item.createdAt.toISOString();
    }

    return `${String(item.createdAt)}-${String(item.id)}`;
  }

  return (
    <Container
      ref={mergeRefs<HTMLDivElement>(dropProvided.innerRef, reference)}
    >
      {items.map((item: BoardItem, index: number) => (
        <Item
          key={getKey(item)}
          index={index}
          item={item}
          draggableId={draggableId}
          disabled={disabled}
          editable={!disabled}
          onRemove={onRemove}
          onChange={onChange}
          onImageClick={onImageClick}
        />
      ))}
      {dropProvided.placeholder}
    </Container>
  );
};

export default Items;

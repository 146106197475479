import React, { useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { Container, Content, Header } from './Section.styles';
import Dropzone from '../Dropzone/Dropzone';
import { BoardColumn, BoardItem } from '../../../../typings';
import Name from './components/Name/Name';
import FileUtils from '../../../../utils/file.utils';

interface SectionProps {
  data: BoardColumn;
  onChange: (data: BoardColumn) => void;
  onDelete: (data: BoardColumn) => void;
  onImageClick: (item: BoardItem) => void;
  disabled: boolean;
  stack: {
    first: boolean;
    last: boolean;
    single: boolean;
  };
}

const Section: React.FC<SectionProps> = ({
  data,
  disabled,
  onChange,
  onDelete,
  onImageClick,
  stack,
}: SectionProps) => {
  function getItemsOrdered(items: BoardItem[]): BoardItem[] {
    return items.map((item, index) => ({
      ...item,
      order: index,
    }));
  }

  function handleAdd(newItems: BoardItem[]): void {
    const itemsOrdered = getItemsOrdered([...newItems, ...data.items]);
    onChange({ ...data, items: itemsOrdered });
  }

  function handleMove(itemsReordered: BoardItem[]): void {
    onChange({ ...data, items: itemsReordered });
  }

  function handleItemChange(item: BoardItem): void {
    if (item.value !== '') {
      data.items[item.order as number] = item;
      onChange(data);
    }
  }

  function handleRemove(item: BoardItem) {
    const itemsUpdated = data.items.filter((i) => i.order !== item.order);
    const itemsOrdered = getItemsOrdered(itemsUpdated);
    onChange({ ...data, items: itemsOrdered });
  }

  const imageAssets = useMemo(
    (): number =>
      data.items.filter((item) => new FileUtils(item.value).isImage()).length,
    [data.items]
  );

  return (
    <Draggable
      draggableId={`section-${data.order}`}
      index={data.order}
      isDragDisabled={disabled}
    >
      {(provided) => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          id={`section-${data.order}`}
        >
          <Header>
            <Name
              name={data.name}
              placeholder={data.placeholder}
              totalAssets={imageAssets}
              stack={stack}
              disabled={disabled}
              onChange={(name) => onChange({ ...data, name })}
              onDelete={() => onDelete(data)}
              onClick={() => onImageClick(data.items[0])}
              dragProps={provided.dragHandleProps}
            />
          </Header>

          <Content>
            <Dropzone
              draggableId={`section-${data.order}`}
              disabled={disabled}
              items={data.items}
              onAdd={handleAdd}
              onRemove={handleRemove}
              onInputChange={handleItemChange}
              onImageClick={onImageClick}
              maxFiles={100}
            />
          </Content>
        </Container>
      )}
    </Draggable>
  );
};

export default Section;

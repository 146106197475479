import React, { useState } from 'react';
import { useMasonry, usePositioner, useResizeObserver } from 'masonic';
import { useSize, useScroller } from 'mini-virtual-list';

import { Container, Content, Header, Image } from './GridView.styles';
import ImageUtils from '../../../../utils/image.utils';

export interface GridViewItem {
  id: number;
  columnId: number;
  url: string;
}

interface GridViewProps {
  images: Array<GridViewItem>;
  onClick: (columnId: number, itemId: number) => void;
}

const GridView: React.FC<GridViewProps> = ({
  images,
  onClick,
}: GridViewProps) => {
  const [itemToZoom, setItemToZoom] = useState<GridViewItem | null>(null);

  const containerRef = React.useRef(null);
  const { height, width } = useSize(containerRef);
  const { scrollTop, isScrolling } = useScroller(containerRef, { fps: 12 });
  const positioner = usePositioner({
    columnGutter: 12,
    columnWidth: 155,
    rowGutter: 12,
    width,
    maxColumnCount: 10,
    columnCount: 10,
  });
  const resizeObserver = useResizeObserver(positioner);

  return (
    <Container>
      <Header>
        <p>All assets ({images.length})</p>
        <p>Organized chronologically</p>
      </Header>

      <Content ref={containerRef}>
        {useMasonry({
          positioner,
          resizeObserver,
          items: images,
          height,
          scrollTop,
          isScrolling,
          overscanBy: 3,
          render: ({ data: item, width: itemWidth }) => (
            <Image
              src={new ImageUtils(item.url).getSmall()}
              width={itemWidth}
              onClick={() => onClick(item.columnId, item.id)}
            />
          ),
        })}

        {/* <CarouselModal
        show={Boolean(itemToZoom)}
        images={imagesOrderedByCreatedAt as unknown as ProjectAsset[]}
        selectedItem={imagesOrderedByCreatedAt.findIndex(
          (image) => image.url === itemToZoom?.url
        )}
        onClose={() => setItemToZoom(null)}
      /> */}
      </Content>
    </Container>
  );
};

export default GridView;

import React, { useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { CgTrash as ClearIcon } from 'react-icons/cg';
import { Container, Delete } from './Item.styles';

import { BoardItem } from '../../../../../../typings';
import Tooltip from '../../../../../../components/Tooltip/Tooltip';
import FileUtils from '../../../../../../utils/file.utils';
import Image from '../Image/Image';
import Video from '../Video/Video';
import Note from '../Note/Note';

interface ItemProps {
  item: BoardItem;
  index: number;
  editable: boolean;
  draggableId: string;
  disabled: boolean;
  onRemove: (item: BoardItem) => void;
  onChange: (item: BoardItem) => void;
  onImageClick: (item: BoardItem) => void;
}

const Item = ({
  item,
  index,
  editable,
  draggableId,
  disabled,
  onRemove,
  onChange,
  onImageClick,
}: ItemProps) => {
  const isContent = useMemo(
    (): boolean =>
      new FileUtils(item.value).isImage() ||
      new FileUtils(item.value).isVideo(),
    [item.value]
  );

  const isVideo = useMemo(
    (): boolean => new FileUtils(item.value).isVideo(),
    [item.value]
  );

  return (
    <Draggable
      key={index}
      draggableId={`${draggableId}-image-${index}`}
      index={index}
      isDragDisabled={disabled}
    >
      {(provided) => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {editable && (
            <Delete onClick={() => onRemove(item)} isContent={isContent}>
              <Tooltip
                title="Delete"
                id={`${String(item.createdAt)}`}
                placement="right"
              >
                <ClearIcon />
              </Tooltip>
            </Delete>
          )}

          {isContent ? (
            <>
              {isVideo ? (
                <Video
                  item={item}
                  disabled={!editable}
                  onClick={onImageClick}
                />
              ) : (
                <Image
                  item={item}
                  disabled={!editable}
                  onClick={onImageClick}
                />
              )}
            </>
          ) : (
            <Note item={item} disabled={!editable} onChange={onChange} />
          )}
        </Container>
      )}
    </Draggable>
  );
};

export default Item;

import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RiQuestionFill as InfoIcon } from 'react-icons/ri';

import { useApp } from '../../../../hooks/useMain';
import {
  Content,
  GlobalStyle,
  HeaderInfos,
  ModalContainer,
  Setting,
  Subtitle,
  Title,
} from './CreateOrEditProjectModal.styles';
import InputField from '../../../../components/InputField/InputField';
import PictureUploader from './components/PictureUploader/PictureUploader';
import { Button, ButtonOutline } from '../../../../components/Button/Button';
import DropdownOptions from './components/DropdownOptions/DropdownOptions';
import ProjectStatusActive from '../../../../components/Icon/icons/ProjectStatusActive';
import ProjectStatusPaused from '../../../../components/Icon/icons/ProjectStatusPaused';
import ProjectStatusKilled from '../../../../components/Icon/icons/ProjectStatusKilled';
import ProjectStatusCompleted from '../../../../components/Icon/icons/ProjectStatusCompleted';
import { BoardStatus, Board } from '../../../../typings';
import UploadClient from '../../../../clients/upload.client';
import BoardClient from '../../../../clients/board.client';
import ROUTES from '../../../../routes';
import JuneService from '../../../../services/june.service';

interface CreateOrEditProjectModalProps {
  open: boolean;
  data: Board;
  onClose: () => void;
  onSave: (data: any) => Promise<void>;
}

const validationSchema = Yup.object({
  name: Yup.string()
    .max(32, 'Must be 32 characters or less')
    .required('Required'),
  description: Yup.string().max(300, 'Must be 300 characters or less'),
  picture: Yup.mixed(),
  status: Yup.string().required('Required'),
});

export const PROJECT_STATUS = [
  {
    name: BoardStatus.ACTIVE,
    icon: <ProjectStatusActive />,
  },
  {
    name: BoardStatus.PAUSED,
    icon: <ProjectStatusPaused />,
  },
  {
    name: BoardStatus.KILLED,
    icon: <ProjectStatusKilled />,
  },
  {
    name: BoardStatus.COMPLETED,
    icon: <ProjectStatusCompleted />,
  },
];

const CreateOrEditProjectModal: React.FC<CreateOrEditProjectModalProps> = ({
  open,
  data,
  onClose,
  onSave,
}: CreateOrEditProjectModalProps) => {
  const { setErrorMessage, setCustomToast, setLoading } = useApp();
  const navigate = useNavigate();

  const isEditing = useMemo(() => Boolean(data.id), [data.id]);

  function handleClose() {
    if (isEditing) {
      onClose();
    } else {
      navigate(ROUTES.DASHBOARD);
    }
  }

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      description: data?.description || '',
      picture: data?.image || undefined,
      status: data?.status || BoardStatus.ACTIVE,
      private: data?.private || true,
    },
    enableReinitialize: true,
    validateOnMount: !isEditing,
    validationSchema,
    onSubmit: async (values) => {
      await onSave(values);
      onClose();
    },
  });

  const imageSrc = useMemo((): string => {
    if ((formik.values.picture as any) instanceof File) {
      return URL.createObjectURL(formik.values.picture as unknown as File);
    }

    return formik.values.picture || '';
  }, [formik.values.picture]);

  return (
    <ModalContainer
      show={open}
      size="md"
      backdrop="static"
      keyboard
      onEscapeKeyDown={handleClose}
      centered
    >
      <GlobalStyle />

      <Modal.Header>
        <PictureUploader
          size="80px"
          data={imageSrc}
          onChange={(item) => formik.setFieldValue('picture', item as File)}
        />
        <HeaderInfos>
          <Title>
            {isEditing ? 'Edit your project' : 'Create a new project'}
          </Title>
          {!isEditing && <Subtitle>Add a project thumbnail</Subtitle>}
        </HeaderInfos>
      </Modal.Header>

      <Modal.Body>
        <Content noValidate onSubmit={formik.handleSubmit}>
          <InputField
            type="text"
            id="name"
            name="Project name"
            placeholder="Viewport branding"
            maxLength={32}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.touched.name ? formik.errors.name : ''}
          />

          <InputField
            type="text"
            id="description"
            name="Description"
            placeholder="What’s the story behind the project? (Optional)"
            maxLength={300}
            rows="5"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            error={formik.touched.description ? formik.errors.description : ''}
          />

          {isEditing && (
            <DropdownOptions
              label="Project status"
              placeholder="Active"
              items={PROJECT_STATUS}
              value={formik.values.status}
              width={336}
              onChange={(value: string) =>
                formik.setFieldValue('status', value)
              }
            />
          )}

          {/* <Setting>
            <span>
              <p>Private project? </p>
              <Tooltip
                id="info"
                title="Private projects do not appear on your public profile and sharing will be invite only."
                placement="bottom"
              >
                <InfoIcon size="18px" color="#C8C9CF" />
              </Tooltip>
            </span>

            <Checkbox
              sizing="32px"
              checked={formik.values.private}
              onChange={(checked) => formik.setFieldValue('private', checked)}
              name="link"
            />
          </Setting> */}
        </Content>
      </Modal.Body>

      <Modal.Footer>
        <ButtonOutline
          type="button"
          width="106px"
          height="48px"
          marginTop="0"
          fontSize="16"
          color="#7F8087"
          borderSize="1px"
          borderRadius="12px"
          onClick={handleClose}
        >
          Cancel
        </ButtonOutline>

        <Button
          type="button"
          disabled={!formik.isValid}
          width="121px"
          height="48px"
          marginTop="0"
          borderRadius="12px"
          onClick={formik.submitForm}
        >
          Continue
        </Button>
      </Modal.Footer>
    </ModalContainer>
  );
};

export default CreateOrEditProjectModal;

/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { RefObject, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { GoPlus as AddIcon } from 'react-icons/go';
import { MdOutlineFileUpload as UploadIcon } from 'react-icons/md';
import { UploadSimple } from '@phosphor-icons/react';
import OutsideClickHandler from 'react-outside-click-handler';
import mergeRefs from 'merge-refs';

import {
  Container,
  Description,
  Content,
  EmptyContent,
  Header,
  DataWrapper,
  GlobalStyle,
  UploadButton,
} from './Dropzone.styles';
import { useApp } from '../../../../hooks/useMain';
import { ButtonOutline } from '../../../../components/Button/Button';
import Spacer from '../../../../components/Spacer/Spacer';
import { BoardItem, UserType } from '../../../../typings';
import Items from './components/Items/Items';

const IMAGES_ACCEPTED = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
const VIDEOS_ACCEPTED = ['video/mp4'];

interface DropzoneProps {
  onAdd: (data: BoardItem[]) => void;
  onRemove: (data: BoardItem) => void;
  onInputChange: (data: BoardItem) => void;
  onImageClick: (item: BoardItem) => void;
  draggableId: string;
  items: BoardItem[];
  disabled: boolean;
  maxFiles: number;
}

const Dropzone: React.FC<DropzoneProps> = ({
  draggableId,
  onAdd,
  onRemove,
  onInputChange,
  onImageClick,
  items,
  maxFiles,
  disabled,
}: DropzoneProps) => {
  const { setErrorMessage, setUpgradeToProModal, isProUser } = useApp();
  const [focused, setFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();
  const itemsRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HTMLDivElement>();
  const emptyContentRef = useRef<HTMLDivElement>();

  function getImageDimensions(src: string): Promise<any> {
    return new Promise((resolve) => {
      const img = new window.Image();

      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };

      img.src = src;
    });
  }

  async function getAllowedFiles(files: File[]): Promise<File[]> {
    const allowed = [];

    for (let file of files) {
      const isImageType = IMAGES_ACCEPTED.includes(file.type);

      if (file.name.includes('image.')) {
        const fileExtension = file.type.split('/')[1];
        const fileRenamed = new File(
          [file],
          `${new Date().toISOString()}.${fileExtension}`,
          {
            type: file.type,
          }
        );
        file = fileRenamed;
      }

      if (isImageType) {
        const dimensions = await getImageDimensions(URL.createObjectURL(file));
        // TODO: Change to the real value later
        if (dimensions?.height >= 100) {
          allowed.push(file);
        }
      } else {
        allowed.push(file);
      }
    }

    return allowed;
  }

  async function onDrop(newFiles: File[], fileRejections: any[]) {
    if (
      fileRejections.length &&
      fileRejections?.[0]?.errors?.[0]?.code === 'file-too-large'
    ) {
      setUpgradeToProModal({
        title: 'File size exceeds 5mb!',
        subtitle: 'Upgrade to Pro for unlimited uploads and larger file sizes.',
      });
      return;
    }

    if (
      fileRejections.length &&
      fileRejections?.[0]?.errors?.[0]?.code === 'too-many-files'
    ) {
      setErrorMessage(`Too many images - Max ${maxFiles}`);
      return;
    }

    if (items.length + newFiles.length > maxFiles) {
      setErrorMessage(`Maximun files allowed are ${maxFiles}`);
      return;
    }

    const allowed = await getAllowedFiles(newFiles);

    if (allowed.length !== newFiles.length) {
      setErrorMessage(
        'Images need to have a height of at least 400px. Please re-upload images with larger height'
      );
    }

    if (allowed?.length) {
      const result = allowed.map((image: File) => ({
        value: image,
        createdAt: new Date(),
      }));

      onAdd?.(result);
    }
  }

  function onAddNote() {
    const result = [
      {
        value: '',
        createdAt: new Date(),
      },
    ];

    onAdd?.(result);
  }

  function handleUpload(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    openUploader();
  }

  function handlePaste(event: ClipboardEvent) {
    const data = event.clipboardData;
    if (!data || !focused) return;

    if (event.clipboardData.files && inputRef.current) {
      inputRef.current.files = event.clipboardData.files;
      inputRef.current?.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }

  function handleClickFocus(event: React.MouseEvent<HTMLDivElement>) {
    if (disabled) return;
    event.stopPropagation();
    event.preventDefault();

    const isClickinInTheComponent =
      containerRef.current &&
      contentRef.current &&
      [
        containerRef.current,
        contentRef.current,
        itemsRef.current,
        headerRef.current,
        emptyContentRef.current,
      ].includes(event.target as any);

    if (isClickinInTheComponent) {
      setFocused(() => !focused);
    }
  }

  function getContentRef(provided: DroppableProvided): any {
    if (items?.length) {
      return contentRef as RefObject<HTMLDivElement>;
    }

    return mergeRefs<HTMLDivElement>(
      provided.innerRef,
      contentRef as RefObject<HTMLDivElement>
    );
  }

  const {
    getRootProps,
    getInputProps,
    open: openUploader,
    inputRef,
  } = useDropzone({
    onDrop,
    maxFiles,
    maxSize: isProUser ? 50000000 : 5000000,
    accept: isProUser
      ? `${IMAGES_ACCEPTED.toString()},${VIDEOS_ACCEPTED.toString()}`
      : IMAGES_ACCEPTED.toString(),
    noDragEventsBubbling: true,
    noClick: true,
  });

  return (
    <OutsideClickHandler onOutsideClick={() => setFocused(false)}>
      <Container
        {...getRootProps()}
        disabled={disabled}
        ref={containerRef}
        onPaste={handlePaste}
        onClick={handleClickFocus}
      >
        <Droppable droppableId={draggableId} type="image">
          {(provided) => (
            <>
              <Content
                disabled={disabled}
                focused={focused}
                {...provided.droppableProps}
                ref={getContentRef(provided)}
              >
                <input {...getInputProps()} />

                {!disabled && items.length === 0 && (
                  <EmptyContent
                    ref={emptyContentRef as RefObject<HTMLDivElement>}
                  >
                    <Description>Drag and drop or</Description>
                    <UploadButton type="button" onClick={handleUpload}>
                      <UploadSimple size={16} color="#7F8087" weight="bold" />{' '}
                      Upload
                    </UploadButton>
                    <Description>JPG, PNG, GIF accepted</Description>
                  </EmptyContent>
                )}

                {Boolean(items?.length) && (
                  <DataWrapper>
                    <GlobalStyle />

                    {!disabled && (
                      <Header ref={headerRef as RefObject<HTMLDivElement>}>
                        <ButtonOutline
                          type="button"
                          width="118px"
                          height="40px"
                          borderRadius="8px"
                          borderSize="1px"
                          color="#7F8087"
                          borderColor="#C8C9CF"
                          marginTop="0"
                          fontWeight="500"
                          onClick={openUploader}
                        >
                          <UploadIcon size="20px" />
                          <Spacer width={6} />
                          Upload
                        </ButtonOutline>

                        <ButtonOutline
                          type="button"
                          width="118px"
                          height="40px"
                          borderRadius="8px"
                          borderSize="1px"
                          color="#7F8087"
                          borderColor="#C8C9CF"
                          marginTop="0"
                          fontWeight="500"
                          onClick={onAddNote}
                        >
                          <AddIcon size="16px" />
                          <Spacer width={6} />
                          Add note
                        </ButtonOutline>
                      </Header>
                    )}

                    <Items
                      draggableId={draggableId}
                      reference={itemsRef as RefObject<HTMLDivElement>}
                      items={items}
                      disabled={disabled}
                      onRemove={onRemove}
                      onChange={onInputChange}
                      onImageClick={onImageClick}
                      dropProvided={provided}
                    />
                  </DataWrapper>
                )}
              </Content>
            </>
          )}
        </Droppable>
      </Container>
    </OutsideClickHandler>
  );
};

export default Dropzone;

import styled from 'styled-components/macro';

interface DeleteProps {
  isContent: boolean;
}

export const Delete = styled.div<DeleteProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.gray3};
  width: 24px;
  height: 24px;
  border-radius: 4px;
  position: absolute;
  top: ${({ isContent }) => (isContent ? '4px' : '8px')};
  right: 4px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;

  svg:hover {
    color: ${({ theme }) => theme.red};
  }
`;

export const Container = styled.div`
  position: relative;
  margin-bottom: 12px;

  &:hover ${Delete} {
    opacity: 1;
  }
`;
